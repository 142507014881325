import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import useBreakpoint from '~utils/useBreakpoint'
import ProjectMobile from '~components/ProjectMobile'
import ProjectDesktop from '~components/ProjectDesktop'
import Seo from '~components/Seo'

const Project = ({ data }) => {
	const { isMobile } = useBreakpoint()
	const { project } = data
	return (
		<>
			<Seo 
				title={project?.title}
				metaTitle={project.seo?.metaTitle}
				description={project.seo?.metaDescription}
				image={project.seo?.socialImage}
			/> 
			{isMobile ? <ProjectMobile data={data}/> : <ProjectDesktop data={data}/>}
		</>
	)
}

export const query = graphql`
  query ProjectQuery($slug: String) {
    project: sanityProject(slug: {current: {eq: $slug}}) {
      title
			seo{
				...seo
			}
			date
			categories{
				title
				slug{
					current
				}
			}
			mediaRows{
				_key
				media{
					...imageWithAlt
					...video
				}
			}
			infoRows{
				title
				subtitle
				link{
					...link
				}
			}
			text: _rawText(resolveReferences: {maxDepth: 4})
			awards{
				title
				subtitle
				link{
					...link
				}
			}
			creativeTeam{
				title
				subtitle
				link{
					...link
				}
			}
			seo{
				...seo
			}
    }
  }
`


Project.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
}

export default Project