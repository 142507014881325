import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

const Video = ({ src, className }) => {
	return (
		<video 
			className={className} 
			playsInline 
			autoPlay 
			muted 
			loop 
			css={css`min-width: 20px;`}
		>
			<source src={src ?? './videos/placeholder.mp4'} />
		</video>
	)
}

Video.propTypes = {
	src: PropTypes.string,
	className: PropTypes.node,
}

export default Video
